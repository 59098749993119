import axios from "axios";
import React, { useContext, useReducer } from "react";
import reducer from "../reducers/success_reducer";

import { GET_STRIPE_SESSION_STATUS, PAID, SUBSCRIBE, RENEW } from "../actions";

const initialState = {
  transComplete: true,
  transSuccess: false,
  accountCreated: false,
  accountUpdated: false,
  session: {},
};

const SuccessContext = React.createContext();

export const SuccessProvider = ({ children }) => {
  //   useEffect(() => {
  //     getSessionStatus(sessionid);
  //   }, [sessionid]);
  const restUrl = process.env.REACT_APP_REST_ENDPOINT;
  const authPayUrl = process.env.REACT_APP_AUTH_DOIMAN;
  const bearer_token = process.env.REACT_APP_BEARER_TOKEN;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSessionStatus = async (sessionid) => {
    const stripeUrl = `${authPayUrl}/stripe/get-checkout-session?sessionid=${sessionid}`;
    const response = await axios.get(stripeUrl);
    //the Data here could be error, need to check for error as well.
    console.log(response);
    if (response.status === 200) {
      const data = response.data;
      const jsonData = {
        customerId: data.client_reference_id,
        stripeCustomerId: data.customer,
        email: data.customer_details.email,
        mode: data.mode,
        paymentType: data.metadata.payType,
        paymentStatus: data.payment_status,
        status: data.status,
        amountSubTotal: data.amount_subtotal,
        amountTotal: data.amount_total,
        amountDiscount: data.total_details.amount_discount,
        amountTax: data.total_details.amount_tax,
        stripeId: data.id,
        stripeInvoiceId: data.invoice,
        stripeSubscriptionId: data.subscription,
        stripeCurrency: data.currency,
      };
      const payResponse = await axios.post(
        `${authPayUrl}/payment/payment`,
        jsonData
      );
      console.log(payResponse);
      if (
        response.data.payment_status === "paid" &&
        response.data.status === "complete"
      ) {
        try {
          console.log(data);
          if (data.metadata.payType === RENEW) {
            //Renew Subscription code here
            const jsonData = {
              sessionId: sessionid,
            };
            const createActResponse = await axios.post(
              `${authPayUrl}/auth/renew`,
              jsonData
            );
            if (createActResponse.response === undefined) {
              if (createActResponse.status === 200) {
                //const profUserData = await createActResponse.data;
                dispatch({
                  type: GET_STRIPE_SESSION_STATUS,
                  payload: {
                    data: data,
                    transSuccess: true,
                    transComplete: true,
                    accountUpdated: true,
                  },
                });
              } else {
                dispatch({
                  type: GET_STRIPE_SESSION_STATUS,
                  payload: {
                    data: null,
                    transSuccess: true,
                    transComplete: true,
                    accountUpdated: false,
                  },
                });
              }
            } else {
              dispatch({
                type: GET_STRIPE_SESSION_STATUS,
                payload: {
                  data: null,
                  transSuccess: true,
                  transComplete: true,
                  accountUpdated: false,
                },
              });
            }
          } else {
            const config = {
              headers: {
                Authorization: `Bearer ${bearer_token}`,
              },
            };
            const jsonData = {
              fullname: data.metadata.firmName,
              email: data.metadata.email,
              country: data.metadata.country,
              contact_person: data.metadata.contactPerson,
              product: data.metadata.product,
              contact_phone: data.metadata.phone,
              sub_model: PAID,
              is_active_sub: true,
              users_allowed: data.metadata.totalUsers,
            };
            const createActResponse = await axios.post(
              `${restUrl}/professional`,
              jsonData,
              config
            );
            console.log(createActResponse);
            if (createActResponse.response === undefined) {
              if (createActResponse.status === 200) {
                //const profUserData = await createActResponse.data;
                dispatch({
                  type: GET_STRIPE_SESSION_STATUS,
                  payload: {
                    data: data,
                    transSuccess: true,
                    transComplete: true,
                    accountCreated: true,
                  },
                });
              } else {
                dispatch({
                  type: GET_STRIPE_SESSION_STATUS,
                  payload: {
                    data: null,
                    transSuccess: true,
                    transComplete: true,
                    accountCreated: false,
                  },
                });
              }
            } else {
              dispatch({
                type: GET_STRIPE_SESSION_STATUS,
                payload: {
                  data: null,
                  transSuccess: true,
                  transComplete: true,
                  accountCreated: false,
                },
              });
            }
          }
        } catch (error) {
          //need to code more on error handling
          console.log(error);
          dispatch({
            type: GET_STRIPE_SESSION_STATUS,
            payload: {
              data: null,
              transSuccess: true,
              transComplete: true,
              accountCreated: false,
            },
          });
        }
      } else {
        dispatch({
          type: GET_STRIPE_SESSION_STATUS,
          payload: {
            data: response.data,
            transSuccess: false,
            transComplete: true,
            accountCreated: false,
          },
        });
      }
    } else {
      dispatch({
        type: GET_STRIPE_SESSION_STATUS,
        payload: {
          data: response.data,
          transSuccess: false,
          transComplete: true,
          accountCreated: false,
        },
      });
    }
  };

  return (
    <SuccessContext.Provider value={{ ...state, getSessionStatus }}>
      {children}
    </SuccessContext.Provider>
  );
};

export const UseSuccessContext = () => {
  return useContext(SuccessContext);
};
