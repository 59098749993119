import React from "react";

function SuccessRegion() {
  const appUrl = process.env.REACT_APP_LAUDITOR_URL;
  console.log();
  const redirect = (e) => {
    e.preventDefault();
    window.location.href = appUrl;
  };
  return (
    <div className="section section-center">
      <div className="success">
        <div className="spacer">&nbsp;</div>
        <div className="success-title">Account Created Successfully</div>
        <div className="success-msg">
          Thank you for your purchase. Your payment has been successfully
          processed.{" "}
          <b>
            An email with the account details has been sent to your registered
            email address.
          </b>
        </div>
        <div className="success-msg">
          If you have any questions or need further assistance, please don't
          hesitate to contact our customer support.
        </div>
        <button
          id="btnSubmit"
          name="btnSubmit"
          className="btn-small hot active"
          onClick={redirect}
        >
          Sign in
        </button>
      </div>
    </div>
  );
}

export default SuccessRegion;
