import React from "react";

const FailureRegion = () => {
  return (
    <div className="section section-center">
      <div className="success">
        <div class="xcircle">
          <span className="icon">&#10006;</span>
        </div>
        <div className="spacer">&nbsp;</div>
        <div className="failure-title">Account creation failed</div>
        <div className="success-msg">
          Payment failed and unable to create account, please try again later.
        </div>
        <div className="success-msg">
          If you have any questions or need further assistance, please don't
          hesitate to contact our customer support.
        </div>
      </div>
    </div>
  );
};

export default FailureRegion;
