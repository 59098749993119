import React, { useEffect } from "react";
import { UseSuccessContext } from "../context/success_context";
import { useParams } from "react-router-dom";
import StatusRegion from "../components/StatusRegion";
import SuccessRegion from "../components/SuccessRegion";
import FailureRegion from "../components/FailureRegion";
import Header from "../components/Header";
import RenewRegion from "../components/RenewRegion";

const Success = () => {
  const { sessionid } = useParams();

  const {
    session,
    transComplete,
    transSuccess,
    accountCreated,
    accountUpdated,
    getSessionStatus,
  } = UseSuccessContext();

  useEffect(() => {
    if (transComplete) {
      console.log(`sessionid = ${sessionid}`);
      getSessionStatus(sessionid);
    }
  }, [sessionid, transComplete]);

  console.log(transComplete, accountCreated, transSuccess);
  if (transComplete && !transSuccess && !accountCreated && !accountUpdated)
    return (
      <>
        <Header />
        <StatusRegion
          transComplete={transComplete}
          transSuccess={transSuccess}
          accountCreated={accountCreated}
          accountUpdated={accountUpdated}
        />
      </>
    );
  else if (transComplete && transSuccess && accountCreated)
    return (
      <>
        <Header />
        <StatusRegion
          transComplete={transComplete}
          transSuccess={transSuccess}
          accountCreated={accountCreated}
          accountUpdated={accountUpdated}
        />
        <SuccessRegion />
      </>
    );
  else if (transComplete && transSuccess && accountUpdated)
    return (
      <>
        <Header />
        <StatusRegion
          transComplete={transComplete}
          transSuccess={transSuccess}
          accountCreated={accountCreated}
          accountUpdated={accountUpdated}
        />
        <RenewRegion />
      </>
    );
  else
    return (
      <>
        <Header />
        <StatusRegion
          transComplete={transComplete}
          transSuccess={transSuccess}
          accountCreated={accountCreated}
          accountUpdated={accountUpdated}
        />
        <FailureRegion />;
      </>
    );
};

export default Success;
