import React, { useEffect, useState, useRef } from "react";
import { useRegisterContext } from "../context/register_context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import Header from "../components/Header";
import Recaptcha from "react-google-recaptcha";

const Register = () => {
  const btnSubmitRef = useRef(null); //used for Button Toggling CSS
  const captchaRef = useRef(null);
  const parsedqs = queryString.parse(window.location.search); //Parse Query String
  let { prod, users, countrycode } = parsedqs || {
    prod: "lauditor",
    users: 1,
    countrycode: "",
  };
  prod = prod || "lauditor";
  try {
    users = users || 1;
    users = parseInt(users);
  } catch (error) {
    users = 1;
  }
  //const testObj = useRegisterContext();
  const [disabled, setDisabled] = useState(true);

  const [firmclass, setFirmclass] = useState("mod-input");
  const [emailclass, setEmailclass] = useState("mod-input");
  const [phoneclass, setPhoneclass] = useState("mod-input");
  const [cpersonclass, setCpersonclass] = useState("mod-input");
  const [countryclass, setCountryclass] = useState("mod-select");
  const [minvalue, setMinvalue] = useState(countrycode === "IN" ? 3 : 1);
  const [usersclass, setUsersclass] = useState("mod-input");

  const [firmmsg, setFirmmsg] = useState("");
  const [emailmsg, setEmailmsg] = useState("");
  const [phonemsg, setPhonemsg] = useState("");
  const [countrymsg, setCountrymsg] = useState("");
  const [cpersonmsg, setCpersonmsg] = useState("");
  const [usersmsg, setUsersmsg] = useState("");

  const agreeClick = (e) => {
    const isValid = isValidForm();
    // console.log(isValid);
    if (isValid && e.target.checked) {
      setDisabled(false);
      addRemoveClass(false);
    } else {
      setDisabled(true);
      addRemoveClass(true);
    }
  };

  const addRemoveClass = (status) => {
    if (status) {
      btnSubmitRef.current.classList.remove("active");
      btnSubmitRef.current.classList.add("inactive");
    } else {
      btnSubmitRef.current.classList.remove("inactive");
      btnSubmitRef.current.classList.add("active");
    }
  };

  const notify = (error, msg) => {
    if (error) toast.error(msg);
    else toast.success(msg);
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "email":
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = isValidFields();
    if (isValid) {
      //this.setState({[name]: value}, () => { this.validateField(name,value)});
      const token = captchaRef.current.getValue();
      updateCaptchaToken(token);
      captchaRef.current.reset();
      saveProfUser(token);
    }
  };
  // console.log(testObj);
  const {
    fname,
    email,
    phone,
    country,
    cperson,
    countries,
    //qty,
    saveStarted,
    saveEnded,
    saved,
    message,
    customerRefId,
    updateInputValue,
    saveProfUser,
    redirectUrl,
    updateQty,
    updateCaptchaToken,
    updateCountry,
  } = useRegisterContext();

  let { qty, product } = useRegisterContext();
  qty = users > qty ? users : qty;
  qty = parseInt(qty);
  //console.log(qty);
  product = prod || product;

  // const triggerInput = (enteredName, enteredValue) => {
  //   const input = document.getElementById("qty");
  //   console.log(input);
  //   const lastValue = input.value;
  //   input.value = enteredValue;
  //   const ev = document.createEvent("Event");
  //   ev.initEvent("change", true, false);
  //   input.dispatchEvent(ev);
  // };

  useEffect(() => {
    updateQty(qty);
  }, [qty, updateQty]);

  useEffect(() => {
    updateCountry(countrycode);
  }, [countrycode, updateCountry]);

  useEffect(() => {
    if (!saved && saveEnded && message !== "") {
      notify(!saved, message);
    }
  }, [saved, message, saveEnded]);

  useEffect(() => {
    if (redirectUrl !== "") {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  //Function used to limit the number of digits in Number field
  const limitInput = (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value.length >= 3) {
      e.target.value = e.target.value.slice(0, 2);
    }
  };

  const isValidForm = () => {
    let isValid = true;
    resetError();
    // console.log("on blur");
    if (document.getElementById("fname").value.trim() === "" && isValid) {
      isValid = false;
      setFirmclass("fld-error");
      setFirmmsg("Firm Name is required.");
      // setFirmerror(true);
    } else if (
      document.getElementById("fname").value.trim().length < 3 &&
      isValid
    ) {
      isValid = false;
      setFirmclass("fld-error");
      setFirmmsg("Firm Name should have minumum of 3 characters.");
      // setFirmerror(true);
    } else if (
      document.getElementById("email").value.trim() === "" &&
      isValid
    ) {
      isValid = false;
      setEmailclass("fld-error");
      setEmailmsg("Email is required.");
    } else if (
      !document
        .getElementById("email")
        .value.trim()
        .match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/) &&
      isValid
    ) {
      isValid = false;
      setEmailclass("fld-error");
      setEmailmsg("Email is invalid.");
    } else if (
      document.getElementById("country").value.trim() === "" &&
      isValid
    ) {
      isValid = false;
      setCountryclass("fldselect-err");
      setCountrymsg("Country is required.");
    } else if (
      document.getElementById("cperson").value.trim() === "" &&
      isValid
    ) {
      isValid = false;
      setCpersonclass("fld-error");
      setCpersonmsg("Contact person is required.");
    } else if (
      document.getElementById("cperson").value.trim().length < 3 &&
      isValid
    ) {
      isValid = false;
      setCpersonclass("fld-error");
      setCpersonmsg("Contact person should have minumum of 3 characters.");
    } else if (
      document.getElementById("phone").value.trim() === "" &&
      isValid
    ) {
      isValid = false;
      setPhoneclass("fld-error");
      setPhonemsg("Phone is required.");
    } else if (
      !document
        .getElementById("phone")
        .value.trim()
        .match(/^[0-9]{10}$/)
    ) {
      isValid = false;
      setPhoneclass("fld-error");
      setPhonemsg("Phone should be 10 digit number.");
    } else if (parseInt(document.getElementById("qty").value) > 10) {
      isValid = false;
      setUsersclass("fld-error");
      setUsersmsg("Number of users should be less than or equal to 10");
    } else {
      // console.log(document.getElementById("qty").value);
      resetError();
    }
    return isValid;
  };

  const isValidFields = () => {
    let isValid = true;
    resetError();
    if (document.getElementById("fname").value.trim() === "") {
      isValid = false;
      setFirmclass("fld-error");
      setFirmmsg("Firm Name is required.");
      // setFirmerror(true);
    }
    if (document.getElementById("fname").value.trim().length < 3) {
      isValid = false;
      setFirmclass("fld-error");
      setFirmmsg("Firm Name should have minumum of 3 characters.");
      // setFirmerror(true);
    }
    if (document.getElementById("email").value.trim() === "") {
      isValid = false;
      setEmailclass("fld-error");
      setEmailmsg("Email is required.");
    }
    if (
      !document
        .getElementById("email")
        .value.trim()
        .match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)
    ) {
      isValid = false;
      setEmailclass("fld-error");
      setEmailmsg("Email is invalid.");
    }
    if (document.getElementById("country").value.trim() === "") {
      isValid = false;
      setCountryclass("fldselect-err");
      setCountrymsg("Country is required.");
    }
    if (document.getElementById("cperson").value.trim() === "") {
      isValid = false;
      setCpersonclass("fld-error");
      setCpersonmsg("Contact person is required.");
    }
    if (document.getElementById("cperson").value.trim().length < 3 && isValid) {
      isValid = false;
      setCpersonclass("fld-error");
      setCpersonmsg("Contact person should have minumum of 3 characters.");
    }
    if (document.getElementById("cperson").value.trim() === "" && isValid) {
      isValid = false;
      setCpersonclass("fld-error");
      setCpersonmsg("Contact person is required.");
    }
    if (document.getElementById("phone").value.trim() === "") {
      isValid = false;
      setPhoneclass("fld-error");
      setPhonemsg("Phone is required.");
    }
    if (
      !document
        .getElementById("phone")
        .value.trim()
        .match(/^[0-9]{10}$/)
    ) {
      isValid = false;
      setPhoneclass("fld-error");
      setPhonemsg("Phone should be 10 digit number.");
      // console.log("phone is invalid");
    }
    return isValid;
  };

  const resetError = () => {
    setFirmclass("mod-input");
    setEmailclass("mod-input");
    setPhoneclass("mod-input");
    setCountryclass("mod-select");
    setCpersonclass("mod-input");
    setUsersclass("mod-input");
    setFirmmsg("");
    setPhonemsg("");
    setEmailmsg("");
    setCpersonmsg("");
    setCountrymsg("");
    setUsersmsg("");
  };

  const validateFields = (e) => {
    const isValid = isValidForm();
    if (isValid) {
      if (document.getElementById("terms").checked) {
        setDisabled(false);
        addRemoveClass(false);
      } else {
        setDisabled(true);
        addRemoveClass(true);
      }
    } else {
      setDisabled(true);
      addRemoveClass(true);
    }
  };

  return (
    <>
      <Header />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        bodyClassName="toastBody"
      />

      <div className="section section-center">
        {/* <div className="title">Sign up</div> */}

        <form
          className="mod-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <img src="../logo.png" alt="company logo" className="logo" />
          <div className="form-group">
            <p className="sub-title">Complete the form to subscribe.</p>
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="fname">
              Firm Name
            </label>
            <input
              className={firmclass}
              maxLength={50}
              type="text"
              id="fname"
              name="fname"
              value={fname}
              onChange={updateInputValue}
              onBlur={validateFields}
              autoFocus
              required
            />
            <div className="error-msg">{firmmsg}</div>
            <input type="hidden" id="product" name="product" value={product} />
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="email">
              Email
            </label>
            <input
              className={emailclass}
              maxLength={255}
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={updateInputValue}
              onBlur={validateFields}
              required
            />
            <div className="error-msg">{emailmsg}</div>
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="country">
              Country
            </label>
            <select
              className={countryclass}
              id="country"
              name="country"
              value={country}
              onChange={updateInputValue}
              onBlur={validateFields}
              required
            >
              {countries.map((country) => {
                return (
                  <option key={country[1]} value={country[1]}>
                    {country[0]}
                  </option>
                );
              })}
            </select>
            <div className="error-msg">{countrymsg}</div>
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="cperson">
              Contact Person
            </label>
            <input
              className={cpersonclass}
              maxLength={50}
              type="text"
              id="cperson"
              name="cperson"
              value={cperson}
              onChange={updateInputValue}
              onBlur={validateFields}
              required
            />
            <div className="error-msg">{cpersonmsg}</div>
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="phone">
              Phone
            </label>
            <input
              className={phoneclass}
              maxLength={10}
              type="text"
              id="phone"
              name="phone"
              value={phone}
              onChange={updateInputValue}
              onBlur={validateFields}
              required
            />
            <div className="error-msg">{phonemsg}</div>
          </div>

          <div className="form-group">
            <label className="mod-label" htmlFor="users">
              Number of Users
            </label>
            <input
              className={usersclass}
              type="number"
              maxLength={2}
              id="qty"
              name="qty"
              value={qty}
              min={minvalue}
              max={10}
              onChange={updateInputValue}
              onInput={limitInput}
              onBlur={validateFields}
              required
            />
            <div className="error-msg">{usersmsg}</div>
          </div>
          <div className="form-group">
            <label className="mod-label" htmlFor="terms">
              <input
                className="mod-checkbox"
                id="terms"
                name="terms"
                type="checkbox"
                onClick={agreeClick}
              />
              I agree to the Main Services Agreement.
            </label>
          </div>
          <div className="form-group">
            <p className="help-text">
              By sign up, you agree to our{" "}
              <a href="https://digicoffer.com/digicoffer_terms.pdf">Terms</a> &
              &nbsp;
              <a href="https://digicoffer.com/privacy.html">Privacy Policy</a>
            </p>
          </div>
          <div className="form-group">
            <div className="cap-center">
              <Recaptcha
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              />
            </div>
          </div>
          <div className="button-container">
            <button
              id="btnSubmit"
              name="btnSubmit"
              ref={btnSubmitRef}
              disabled={disabled}
              className="btn-small hot inactive"
              onClick={handleSubmit}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
