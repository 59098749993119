import axios from "axios";
import React, { useEffect, useContext, useReducer, useState } from "react";
import reducer from "../reducers/renew_reducer";

import {
  GET_PROFUSER_BEGIN,
  GET_PROFUSER_SUCCESS,
  GET_PROFUSER_ERROR,
  UPDATE_REG_VALUE,
  FAILED,
  RENEW,
  SAVED,
  MATCHED,
} from "../actions";
import userEvent from "@testing-library/user-event";

const initialState = {
  email: "",
  qty: 1,
  captchatoken: "",
  searchStarted: false,
  searchEnded: true,
  searchStatus: "",
  message: "",
  customerRefId: "",
  redirectUrl: "",
};

const RenewContext = React.createContext();

export const RenewProvider = ({ children }) => {
  const authPayUrl = process.env.REACT_APP_AUTH_DOIMAN;
  const [state, dispatch] = useReducer(reducer, initialState);
  const qty = state.qty === undefined || state.qty === "" ? 1 : state.qty;
  const updateCaptchaToken = (token) => {
    initialState.captchatoken = token;
  };
  //function to update the initial Number of users value passed as license
  const updateQty = (qty) => {
    initialState.qty = qty;
  };

  const updateEmail = (email) => {
    initialState.email = email;
  };

  const updateRedirectUrl = (url) => {
    initialState.redirectUrl = "";
  };

  const updateInputValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_REG_VALUE, payload: { name, value } });
  };

  const findProfUser = async (token) => {
    const getUserUrl = `${authPayUrl}/auth/renewsubscription`;
    const qty = state.qty === undefined || state.qty === "" ? 1 : state.qty;
    dispatch({
      type: GET_PROFUSER_BEGIN,
      payload: { searchStarted: true, searchEnded: false, message: "" },
    });
    try {
      const jsonData = {
        email: state.email,
        totalUsers: qty,
        token: token,
      };
      let response = "";
      response = await axios.post(getUserUrl, jsonData);
      const profUserData = await response;
      //call the stripe payment
      const stresponse = await stripePay(
        profUserData.id,
        RENEW,
        profUserData.country
      );
      if (stresponse.status === 200) {
        dispatch({
          type: GET_PROFUSER_SUCCESS,
          payload: {
            customerRefId: profUserData.id,
            user: profUserData,
            searchStatus: MATCHED,
            message: "User email found",
            redirectUrl: stresponse.data.url,
          },
        });
      } else {
        const errorMsg = stresponse.error.message;
        dispatch({
          type: GET_PROFUSER_ERROR,
          payload: { savedStatus: FAILED, message: errorMsg },
        });
      }
    } catch (error) {
      const errorMessage = error.response.data.msg || error.message;
      dispatch({
        type: GET_PROFUSER_ERROR,
        payload: { searchStatus: FAILED, message: errorMessage },
      });
    }
  };

  const stripePay = async (profId, payType, country = "") => {
    const productId =
      country === "India"
        ? process.env.REACT_APP_STRIPE_INDIA_PRODUCT_ID
        : process.env.REACT_APP_STRIPE_BASIC_PRODUCT_ID ||
          "price_1NIPxgJYeHtZmuJDn7zsraEY";

    const stripeUrl = `${authPayUrl}/stripe/create-checkout-session`;
    // console.log(state.qty);
    try {
      const jsonData = {
        priceId: productId,
        qty: state.qty || 1,
        customerRefId: profId,
        email: state.email,
        firmName: "",
        country: "",
        contactPerson: "",
        product: "",
        contactPhone: "",
        token: "",
        paymentType: payType,
      };
      const response = await axios.post(stripeUrl, jsonData);
      if (response.status === 200)
        return { data: response.data, status: response.status };
      else
        return {
          data: null,
          status: response.status,
          error: { message: response.error.message },
        };
      //const profUserData = await response.data;
      //console.log(response);
    } catch (error) {
      return { data: null, status: 400, error: { message: error } };
    }
  };

  return (
    <RenewContext.Provider
      value={{
        ...state,
        updateQty,
        updateEmail,
        updateInputValue,
        updateCaptchaToken,
        findProfUser,
        updateRedirectUrl,
      }}
    >
      {children}
    </RenewContext.Provider>
  );
};

export const useRenewContext = () => {
  return useContext(RenewContext);
};
