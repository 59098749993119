export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";
export const UPDATE_REG_VALUE = "UPDATE_REG_VALUE";

export const SAVE_PROFUSER_BEGIN = "SAVE_PROFUSER_BEGIN";
export const SAVE_PROFUSER_SUCCESS = "SAVE_PROFUSER_SUCCESS";
export const SAVE_PROFUSER_ERROR = "SAVE_PROFUSER_ERROR";

export const MATCHED = "MATCHED";
export const SAVED = "SAVED";
export const FAILED = "FAILED";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILED = "PAYMENT_FAILED";

export const GET_PROFUSER_BEGIN = "GET_PROFUSER_BEGIN";
export const GET_PROFUSER_ERROR = "GET_PROFUSER_ERROR";
export const GET_PROFUSER_SUCCESS = "GET_PROFUSER_SUCCESS";

export const GET_STRIPE_SESSION_STATUS = "GET_STRIPE_SESSION_STATUS";
export const PAID = "paid";
export const FREE = "free";
export const SUBSCRIBE = "SUBSCRIBE";
export const RENEW = "RENEW";
